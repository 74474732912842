import Head from 'next/head';
import { Anton, Poppins } from 'next/font/google';
import dedent from 'dedent';

const poppins = Poppins({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
  variable: '--font-family',
  display: 'swap',
  fallback: ['sans-serif'],
  adjustFontFallback: false,
});

const anton = Anton({
  weight: ['400'],
  subsets: ['latin'],
  variable: '--font-family-heading',
  display: 'swap',
  fallback: ['Impact', 'sans-serif'],
  adjustFontFallback: false,
});

const fontName = anton.style.fontFamily.match(/^'([^']+)'/)?.[1];

export const Fonts = () => {
  return (
    <Head>
      <style
        dangerouslySetInnerHTML={{
          __html: dedent(/* css */ `
            :root{--font-family:${poppins.style.fontFamily};--font-family-heading:${anton.style.fontFamily};}
          `),
        }}
      />

      <script src="/static/lib/fontfaceobserver.standalone.js" defer />
      <script
        dangerouslySetInnerHTML={{
          __html: dedent(/* js */ `
            window.__antonFontName=${JSON.stringify(fontName) || "'Anton'"};
          `),
        }}
      />
      <script src="/static/lib/font-observer.1.js" defer />
    </Head>
  );
};
