import * as prismicH from '@prismicio/helpers';
import { DEFAULT_LOCALE, LOCALES } from '../config/index.js';
import {
  findDynamicPage,
  generateDynamicPageKeyRegExp,
  isDynamicPage,
} from './dynamic-page.js';
import { normalizeSlug } from './normalize-slug.js';

export const generateLinkResolver = ({ articles, categories, pages }) => {
  /**
   * The project's Prismic Link Resolver. This function determines the URL for a given Prismic document.
   *
   * @type {prismicH.LinkResolverFunction}
   */
  return (doc) => {
    if (!doc) {
      return null;
    }

    const locale = LOCALES.find((item) => item.locale === doc.lang);

    if (doc.type === 'page') {
      let data = doc.data;

      if (
        !data ||
        (typeof data == 'object' && Object.keys(data).length === 0)
      ) {
        const foundDoc = pages.find((page) => {
          return page.id === doc.id;
        });

        if (foundDoc) {
          data = foundDoc.data;
        }
      }

      if (data && data.slug) {
        if (isDynamicPage(data)) {
          return null;
        }

        const pathPrefix =
          locale?.shortName !== DEFAULT_LOCALE.shortName &&
          data.slug !== locale?.pathPrefix
            ? locale?.pathPrefix
            : '';

        return normalizeSlug(`${pathPrefix}${data.slug}`);
      }
    }

    if (doc.type === 'article') {
      if (doc.uid) {
        let data = doc.data;

        if (
          !data ||
          (typeof data == 'object' && Object.keys(data).length === 0)
        ) {
          const foundDoc = articles.find((article) => {
            return article.id === doc.id;
          });

          if (foundDoc) {
            data = foundDoc.data;
          }
        }

        let pathPrefix =
          locale?.shortName !== DEFAULT_LOCALE.shortName
            ? locale?.pathPrefix
            : '';

        if (data?.useArticleSlug) {
          pathPrefix += '/article';
        }

        const slug = normalizeSlug(doc.uid);

        const categoryId = data?.category?.id;

        if (categoryId) {
          const category = categories.find((category) => {
            return category.id === categoryId;
          });

          if (category?.data?.name) {
            pathPrefix += `/${normalizeSlug(
              category?.data?.name
            ).toLowerCase()}`;
          }
        }

        return `${pathPrefix}${slug}`.replace(/[/]{2}/g, '/');
      }
    }

    if (doc.type === 'mission') {
      if (doc.uid) {
        const dynamicPage = findDynamicPage({
          pages,
          key: 'mission',
          lang: locale?.locale,
        });

        if (dynamicPage) {
          const pathPrefix =
            locale?.shortName !== DEFAULT_LOCALE.shortName
              ? locale?.pathPrefix
              : '';

          const slug = normalizeSlug(
            dynamicPage.data.slug.replace(
              generateDynamicPageKeyRegExp('mission'),
              doc.uid
            )
          );

          return `${pathPrefix}${slug}`;
        }

        return null;
      }
    }

    if (doc.type === 'category') {
      let data = doc.data;

      if (!data) {
        const foundDoc = categories.find((category) => {
          return category.id === doc.id;
        });

        if (foundDoc) {
          data = foundDoc.data;
        }
      }

      if (data) {
        if (isDynamicPage(data)) {
          return null;
        }

        const pathPrefix =
          locale?.shortName !== DEFAULT_LOCALE.shortName &&
          data.slug !== locale?.pathPrefix
            ? locale?.pathPrefix
            : '';

        return normalizeSlug(
          `${pathPrefix}${normalizeSlug(data?.name).toLowerCase()}`
        );
      }
    }

    return '/';
  };
};
