import React, { createContext, useState, useContext } from 'react';

export const GlobalContext = createContext({
  nonDynamicCompatibleHref: [],
  setNonDynamicCompatibleHref: (a) => {},
});

export const GlobalContextProvider = (props) => {
  const [nonDynamicCompatibleHref, setNonDynamicCompatibleHref] = useState([]);

  return (
    <GlobalContext.Provider
      value={{
        nonDynamicCompatibleHref,
        setNonDynamicCompatibleHref,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
