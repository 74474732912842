import { useGlobalContext } from './context';

export const isLinkDynamicCompatible = (href) => {
  const { nonDynamicCompatibleHref } = useGlobalContext();

  if (
    nonDynamicCompatibleHref.find((item) => {
      return (href || '').startsWith(item);
    })
  ) {
    return false;
  }

  return true;
};
