export const CONFIG = {
  title: 'Batman Escape game Paris | Expérience immersive & inédite',
  titleSuffix: ' - Batman Escape game Paris',

  description:
    "Poussez les portes de Batman Escape pour entrer à Gotham City et relevez les défis posés par tous les plus grands vilains de l'univers du Chevalier Noir !",
};

export const LOCALES = [
  {
    title: 'Français',
    shortName: 'FR',
    locale: 'fr-fr',
    shortCode: 'fr',
    pathPrefix: '/',
    index: true,
    hrefLang: 'x-default',
  },
  {
    title: 'English',
    shortName: 'EN',
    locale: 'en-us',
    shortCode: 'en',
    pathPrefix: '/en',
    index: false,
    hrefLang: 'en',
  },
];

export const DEFAULT_LOCALE = LOCALES[0];
